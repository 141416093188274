@import './styles/PrettyJson.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content {
  width: 80%;
}
.react-datepicker-wrapper {
  width: 100%;
}
.calculator {
  background-color: #ececec;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

#display {
  width: 100%;
  padding: 16px;
  font-size: 24px;
  border: none;
  outline: none;
  text-align: right;
}

.calculator .buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 16px;
}

.calculator button {
  padding: 12px;
  font-size: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calculator button:hover {
  background-color: #dcdcdc;
}
.styles-module_day_title__cc17c {
  background-color: #f0f0f0 !important;
  color: #282c34 !important;
  border-bottom: 1px solid #ccc !important;
  border-left: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}
.styles-module_hour__TLyj9 {
  background-color: #f0f0f0 !important;
  color: #282c34 !important;
  border-right: 1px solid #ccc !important;
  border-left: 1px solid #ccc !important;
}